import { bucketlist_events } from '@prisma/client';
import React, { createContext, useEffect, useState } from 'react';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { toInt } from '../lib/prismaUtils';
import { SnackbarContext } from './snackbarContext';
import { useUserContext } from './UserProvider';

// Define the context type
interface BucketListContextType {
  bucketList: bucketlist_events[];
  addToBucketList: (eventID: number) => Promise<void>;
  removeFromBucketList: (eventID: number) => Promise<void>;
  getBucketList: () => Promise<void>;
}

// Create the context
const BucketListContext = createContext<BucketListContextType | undefined>(undefined);

// Create a provider component
export const BucketListProvider = ({ children }) => {
  const [bucketList, setBucketList] = useState<bucketlist_events[]>([]);
  const { queue } = useContext(SnackbarContext);
  const { t } = useTranslation();
  const { user } = useUserContext();

  // Function to add event to bucket list
  const addToBucketList = async (eventID: number) => {
    return fetch(`/api/events/addToBucketList`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ eventID })
    })
      .then(response => {
        if (response.ok) {
          return response.json() as Promise<bucketlist_events>;
        } else {
          throw response;
        }
      })
      .then(response => setBucketList(prevBucketList => [...prevBucketList, response]))
      .then(() => {
        queue(t('event-bucket-list-added', { bucketListLink: t('event-bucket-list-bucket-list-link') }), false); //FIXME: bucketListLink should be an actual link - but as of time of writing, the bucket list in profile hasn't been created yet. Update 06/27: this is no longer in the design and does not appear in the acceptance criteria. Remove this, clean up the unused strings and prepare for using "undo" instead.
      })
      .catch(error => {
        queue(t('common:generic-error'), true);
        throw error;
      });
  };

  // Function to remove event from bucket list
  const removeFromBucketList = async (eventID: number) => {
    return fetch(`/api/events/removeFromBucketList`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ eventID })
    })
      .then(response => {
        if (!response.ok) {
          throw response;
        }
      })
      .then(() => setBucketList(prevBucketList => prevBucketList.filter(event => toInt(event.a_event_id) !== eventID)))
      .then(() => {
        queue(t('event-bucket-list-removed', { bucketListLink: t('event-bucket-list-bucket-list-link') }), false); //FIXME: bucketListLink should be an actual link - but as of time of writing, the bucket list in profile hasn't been created yet. Update 06/27: this is no longer in the design and does not appear in the acceptance criteria. Remove this, clean up the unused strings and prepare for using "undo" instead.
      })
      .catch(error => {
        queue(t('common:generic-error'), true);
        throw error;
      });
  };

  // Function to get the bucket list
  const getBucketList = async () => {
    try {
      const events = await fetch(`/api/events/getBucketList`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      }).then(response => response.json() as Promise<bucketlist_events[]>);

      setBucketList(events);
    } catch (error) {
      //We can reach this due to not being authed, so just swallow the error silently.
    }
  };

  useEffect(() => {
    // Fetch the bucket list on component mount
    if (user) getBucketList();
  }, []);

  //fetch the bucket list if user has become authenticated
  useEffect(() => {
    if (user) {
      getBucketList();
    }
  }, [user]);

  return (
    <BucketListContext.Provider value={{ bucketList, addToBucketList, getBucketList, removeFromBucketList }}>
      {children}
    </BucketListContext.Provider>
  );
};

// Custom hook to use the BucketListContext
export const useBucketList = () => {
  const context = useContext(BucketListContext);
  if (context === undefined) {
    throw new Error('useBucketList must be used within a BucketListProvider');
  }
  return context;
};
